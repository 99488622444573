import { fluidRootCap, rootCapVar } from './rootCap.css';

/**
 * Multiply by rootCap
 *
 * You probably want to use `spaces` instead.
 *
 * RootCap is the size of the font cap height at the root element.
 * This is the basis for all measurements on the site.
 */
export const rootCap = <Multiplier extends number>(
	multiplier: Multiplier,
): `calc(${Multiplier} * var(--root-cap))` =>
	`calc(${multiplier.toFixed(3) as unknown as Multiplier} * ${
		rootCapVar as `var(--root-cap)`
	})`;

/**
 * Multiply by rootCap in px
 */
export const rootCapInMaxPx = <Multiplier extends number>(
	multiplier: Multiplier,
) => `${(multiplier * fluidRootCap.max.px).toFixed(0)}px`;
