import React from 'react';
import { produce } from 'immer';

import { AccordionContextType } from './Accordion.types';

export const AccordionContext = React.createContext<
	undefined | AccordionContextType
>(undefined);

export const useAccordion = () => {
	const context = React.useContext(AccordionContext);

	if (context == null) {
		throw new Error('useAccordion must be within AccordionProvider');
	}

	const { expandedMap, setExpandedMap } = context;

	const toggleExpandedForItem = (id: string) => {
		setExpandedMap(
			produce((draft) => {
				draft[id] = !draft[id];
			}),
		);
	};

	const setExpandedForAllItems = (isExpanded = false) => {
		setExpandedMap(
			produce((draft) =>
				Object.keys(draft).forEach((id) => {
					draft[id] = isExpanded;
				}),
			),
		);
	};

	return {
		expandedMap,
		setExpandedForAllItems,
		toggleExpandedForItem,
	};
};
