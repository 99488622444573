import React from 'react';

import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import {
	TextBlock,
	TextBlockProps,
} from '@dop/ui-primitives/typography/TextBlock';
import { Svg, SvgProps } from '@dop/ui-primitives/media/Svg';
import { Box, BoxProps } from '@dop/ui-primitives/planeDivision/Box';
import {
	ButtonBlock,
	ButtonBlockProps,
} from '@dop/ui-primitives/interaction/InteractionBlock';

import { LinkBlock, LinkBlockProps } from './LinkBlock';
import { WithLinkTypeIcon } from './WithLinkTypeIcon';

export const FilledBoxIcon = (props: SvgProps) => {
	return <Svg $inlineSize={'auto'} $blockSize={1} {...props} />;
};

export const FilledBoxText = ({
	children,
	href,
	...props
}: TextBlockProps & { href?: string }): React.ReactElement | null => {
	return (
		<TextBlock $fontWeight={'bold'} {...props}>
			<WithLinkTypeIcon href={href}>{children}</WithLinkTypeIcon>
		</TextBlock>
	);
};

const FilledBox = ({
	children,
	disabled,
	...props
}: BoxProps & { disabled?: boolean }): React.ReactElement | null => {
	// Mute disabled button to indicate it can't be clicked
	const boxColor = disabled ? ['positive', 'muted'] : ['positive', 'normal'];

	return (
		<Box
			$padding={[1.5, 2]}
			$backgroundColor={boxColor}
			$border={['2px', 'solid', boxColor]}
			{...props}
		>
			<Shelf $gap={1} $alignItems="start">
				{children}
			</Shelf>
		</Box>
	);
};

/**
 * A link that looks like a button, with a shelf to allow for nice placement of an icon and text.
 * Use with FilledBoxText and FilledBoxIcon as the children. The FilledBoxText component will decide based on the `href` prop if an external-link icon should be displayed.
 */
export const FilledBoxLink = React.forwardRef(
	(
		{
			children,
			boxProps,
			disabled,
			...props
		}: LinkBlockProps & {
			disabled?: boolean;
			boxProps?: BoxProps;
		},
		ref: React.ForwardedRef<HTMLAnchorElement>,
	): React.ReactElement | null => {
		return (
			<LinkBlock ref={ref} {...props} $inlineSize="fit-content">
				<FilledBox disabled={disabled} {...boxProps}>
					{children}
				</FilledBox>
			</LinkBlock>
		);
	},
);

export type FilledBoxLinkProps = React.ComponentPropsWithRef<
	typeof FilledBoxLink
>;

/**
 * A button with a shelf to allow for nice placement of an icon and text.
 * Use with FilledBoxText and FilledBoxIcon as the children.
 */
export const FilledBoxButton = React.forwardRef(
	(
		{
			children,
			disabled,
			boxProps,
			...props
		}: ButtonBlockProps & {
			disabled?: boolean;
			boxProps?: BoxProps;
		},
		ref: React.ForwardedRef<HTMLButtonElement>,
	): React.ReactElement | null => {
		return (
			<ButtonBlock
				ref={ref}
				disabled={disabled}
				{...props}
				$inlineSize="fit-content"
			>
				<FilledBox disabled={disabled} {...boxProps}>
					{children}
				</FilledBox>
			</ButtonBlock>
		);
	},
);

export type FilledBoxButtonProps = React.ComponentPropsWithRef<
	typeof FilledBoxButton
>;
