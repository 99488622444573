import React from 'react';

import { AccordionProps, Item } from './Accordion.types';
import { AccordionItem } from './AccordionItem';
import { AccordionToggleAll } from './AccordionToggleAll';
import { AccordionContext } from './useAccordion';

// reduce item array into a map of id -> isExpanded
export const getExpandedMap = (items: Array<Item>) =>
	items.reduce(
		(acc, { id, defaultExpanded = false }) => ({
			...acc,
			[id]: defaultExpanded,
		}),
		{},
	);

/**
 * Component for expandable accordions; see the Item-type for the required format of your items.
 *
 * This format also allows for items to be expanded by default (simply set the object-property `isExpanded` to true).
 */
export const Accordion = ({
	items,
	children,
}: AccordionProps): React.ReactElement | null => {
	const [expandedMap, setExpandedMap] = React.useState<Record<string, boolean>>(
		() => getExpandedMap(items),
	);

	return (
		<AccordionContext.Provider value={{ expandedMap, setExpandedMap }}>
			{children}
		</AccordionContext.Provider>
	);
};

// The default open-all/close-all buttons
Accordion.ToggleAll = AccordionToggleAll;
// The default list of items
Accordion.Item = AccordionItem;
