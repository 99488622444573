import { SlotElement } from './pageLayout.types';
import { spaces } from '@/uiPrimitives/layout/space.definitions';

export const globalLayoutTheme = {
	rowGap: 3, // in rootCap
	columnGutter: 3,
};

export const addSideSpaceToColumns = (columns: string) => {
	return `var(--layout-side-space) ${columns} var(--layout-side-space)`;
};

export const columnSizes = {
	oneColumn: [
		'var(--layout-side-space)',
		'minmax(0, 1fr)',
		'var(--layout-side-space)',
	],
	threeColumns: [
		'var(--layout-side-space)',
		'minmax(0, 1fr)',
		spaces['5 | betweenParagraphs'],
		'minmax(0, 1fr)',
		spaces['5 | betweenParagraphs'],
		'minmax(0, 1fr)',
		'var(--layout-side-space)',
	],
} as const;

// return the repeat-number based on the amount of filled slots
export const getRowsRepeatAmount = (
	filledSlots: Array<SlotElement | undefined>,
): number => filledSlots.filter(Boolean).length;
