import React from 'react';
import styled, { css } from 'styled-components';

import {
	StyledComponentPropsWithoutRef,
	StyledComponentPropsWithoutRefAs,
} from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import {
	defaultInteractionTransition,
	InteractionZoom,
} from './interactionDefinitions';
import { getZoomCSS } from './interactionStyleFunctions';
import { DOPLink } from '@/uiComposites/interactive/DOPLink';

export type InteractionBlockStaticStyleProps = {
	$zoom?: InteractionZoom;
} & BaseStaticStyleProps;

export type InteractionBlockComponentStyleProps = ComponentStyleProps<
	InteractionBlockStaticStyleProps,
	undefined,
	never
>;

const interactionBlockStaticStyleMap: StyleMap<InteractionBlockStaticStyleProps> =
	{
		...baseStaticStyleMap,
		$transition: (transition = defaultInteractionTransition) =>
			baseStaticStyleMap.$transition(transition),
		$zoom: getZoomCSS,
	};

const interactionBlockStyleMap: ComponentStyleMap<InteractionBlockStaticStyleProps> =
	{
		normal: interactionBlockStaticStyleMap,
	};

/**
 * Component for block links
 *
 * Responsibilities:
 *
 * 1. Semantic link element for accessibility and interaction.
 * 2. Children can react to hover, active and focus state of this element.
 *
 * Note: this component does not react itself to hover, active or focus state.
 */
const StyledABlock = styled(DOPLink)<InteractionBlockComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: block;
			&:any-link {
				color: inherit;
			}
			${getCSSFromStyleProps(interactionBlockStyleMap, props)};
		`;
	}};
`;

export type ABlockProps = StyledComponentPropsWithoutRef<typeof DOPLink> &
	InteractionBlockComponentStyleProps;

export const ABlock = React.forwardRef(
	(props: ABlockProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
		if (props.href == null) return null;

		return <StyledABlock ref={ref} {...props} />;
	},
);
/**
 * Component for block buttons
 *
 * Responsibilities:
 *
 * 1. Semantic button element for accessibility and interaction.
 * 2. Children can react to hover, active and focus state of this element.
 *
 * Note: this component does not react itself to hover, active or focus state.
 */
export const ButtonBlock = styled.button<InteractionBlockComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: block;
			appearance: none;
			${getCSSFromStyleProps(interactionBlockStyleMap, props)};

			& > * {
				/* Fix for Safari; a button will chop off any overflowing content if that content is not set to relative-position. */
				position: relative;
			}
		`;
	}};
`;

export type ButtonBlockProps = StyledComponentPropsWithoutRefAs<
	'button',
	typeof ButtonBlock
>;
