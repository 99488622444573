import { rootCap } from '../base/rootCap';
import { scale1point2 } from '../base/scale.definitions';
import { capSize, fontMetrics } from '../typography/textBlock.css';
import { capSizes } from '../typography/typography.definitions';

/** All available space sizes for gaps and paddings */
export const spaces = {
	'10 | betweenH2Sections': rootCap(scale1point2['10']),
	'9 | asideBlockMargin': rootCap(scale1point2['9']),
	'8 | betweenCards': rootCap(scale1point2['8']),
	'7 | betweenH3Sections': rootCap(scale1point2['7']),
	'6 | betweenH4Sections': rootCap(scale1point2['6']),
	'5 | betweenParagraphs': rootCap(scale1point2['5']),
	'4 | betweenListItems': rootCap(scale1point2['4']),

	...capSizes,
	'0px': '0',
	'1ex': `calc( ${capSize} * ${(
		fontMetrics.xHeight / fontMetrics.capHeight
	).toFixed(3)})`,
} as const;

export type Space = keyof typeof spaces;
