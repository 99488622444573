import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';
import React from 'react';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import { baseStyle } from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import { TextColor } from '../colorTheme/ColorTheme.types';
import {
	textInlineStaticStyleMap,
	TextInlineStaticStyleProps,
} from '../typography/TextInline';
import {
	getFontWeightCSS,
	getHoverTextColorCSS,
	getActiveTextColorCSS,
	getInteractiveTextDecorationLineCSS,
	getNormalTextColorCSS,
} from '../typography/typographyStyleFunctions';
import {
	defaultInteractionTransition,
	InteractionZoom,
} from './interactionDefinitions';
import { getZoomCSS } from './interactionStyleFunctions';
import { DOPLink } from '@/uiComposites/interactive/DOPLink';

export type InteractionInlineInteractiveStyleProps = {
	$fontWeight?: CSSProperty.FontWeight;
	$color?: TextColor;
};
export type InteractionInlineStaticStyleProps = TextInlineStaticStyleProps & {
	$textDecorationLine?: Extract<
		CSSProperty.TextDecorationLine,
		CSSProperty.All | 'none' | 'underline'
	>;
	$zoom?: InteractionZoom;
};

export type InteractionInlineComponentStyleProps = ComponentStyleProps<
	InteractionInlineStaticStyleProps,
	InteractionInlineInteractiveStyleProps,
	never
>;

const interactionInlineStaticStyleMap: StyleMap<
	InteractionInlineStaticStyleProps & InteractionInlineInteractiveStyleProps
> = {
	...textInlineStaticStyleMap,
	$transition: (transition = defaultInteractionTransition) =>
		textInlineStaticStyleMap.$transition(transition),
	$zoom: getZoomCSS,
	$textDecorationLine: getInteractiveTextDecorationLineCSS,
};

const interactionInlineInteractiveStyleMap: StyleMap<InteractionInlineInteractiveStyleProps> =
	{
		$fontWeight: getFontWeightCSS,
		$color: getNormalTextColorCSS,
	};

const interactionInlineStyleMap: ComponentStyleMap<
	InteractionInlineStaticStyleProps,
	InteractionInlineInteractiveStyleProps
> = {
	normal: interactionInlineStaticStyleMap,
	hover: {
		...interactionInlineInteractiveStyleMap,
		$color: getHoverTextColorCSS,
	},
	active: {
		...interactionInlineInteractiveStyleMap,
		$color: getActiveTextColorCSS,
	},
	focus: interactionInlineInteractiveStyleMap,
	checked: interactionInlineInteractiveStyleMap,
	disabled: interactionInlineInteractiveStyleMap,
};

/**
 * Component for inline links
 *
 * Responsibilities:
 *
 * 1. Semantic link element for accessibility and interaction.
 * 2. Reacts itself to hover, active and focus state.
 */
const StyledAInline = styled(DOPLink)<InteractionInlineComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			display: inline;
			${getCSSFromStyleProps(interactionInlineStyleMap, props)};
		`;
	}};
`;

export type AInlineProps = StyledComponentPropsWithoutRef<typeof DOPLink> &
	InteractionInlineComponentStyleProps;

export const AInline = React.forwardRef(
	(props: AInlineProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
		if (props.href == null) return null;

		return <StyledAInline ref={ref} {...props} />;
	},
);

/**
 * Component for block buttons
 *
 * Responsibilities:
 *
 * 1. Semantic button element for accessibility and interaction.
 * 2. Reacts itself to hover, active and focus state.
 */
export const ButtonInline = styled.button<InteractionInlineComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			appearance: none;
			${getCSSFromStyleProps(interactionInlineStyleMap, props)};
		`;
	}};
`;

export type ButtonInlineProps = StyledComponentPropsWithoutRef<
	typeof ButtonInline
>;
