import environmentConfig from 'moduleAlias/environmentConfig';
import { parseUrl } from '@dop/shared/helpers/link';

type ImageSetEntry = {
	widthInPx: number;
	slug: string;
};

export const imageSetMap = {
	large: {
		widthInPx: 1920,
		slug: 'large',
	},

	medium: {
		widthInPx: 850,
		slug: 'medium',
	},

	content: {
		widthInPx: 600,
		slug: 'content',
	},

	header: {
		widthInPx: 500,
		slug: 'header',
	},

	bio: {
		widthInPx: 100,
		slug: 'bio',
	},

	promo: {
		widthInPx: 448,
		slug: 'promo',
	},

	menu: {
		widthInPx: 120,
		slug: 'menu',
	},
} as const;
imageSetMap as ImageSet;

export type ImageSetEntryName = keyof typeof imageSetMap;

type ImageSet = Record<string, ImageSetEntry>;

export const getResponsiveSrc = (
	src: string,
	imageSetEntryName: ImageSetEntryName,
) => {
	let parsedSrc: URL;
	try {
		parsedSrc = new URL(src, environmentConfig.originUrl);
	} catch (error) {
		return undefined;
	}

	const { slug } = imageSetMap[imageSetEntryName];
	const pathnameComponents = parsedSrc.pathname.split('/');

	const index = pathnameComponents.indexOf('binaries');

	if (index < 0) {
		return undefined;
	}

	pathnameComponents.splice(index + 1, 0, slug);

	const responsivePathname = pathnameComponents.join('/');
	parsedSrc.pathname = responsivePathname;

	const responsiveSrc = parsedSrc.href;

	return responsiveSrc;
};

export const getSrcSetEntry = (
	src: string,
	imageSetEntryName: ImageSetEntryName,
) => {
	const { widthInPx } = imageSetMap[imageSetEntryName];

	const responsiveSrc = getResponsiveSrc(src, imageSetEntryName);

	if (responsiveSrc == null) {
		return undefined;
	}

	const srcSetEntry = `${responsiveSrc} ${widthInPx}w`;

	return srcSetEntry;
};

export const getSrcSet = (
	src: string,
	imageSetEntryNames: Array<ImageSetEntryName>,
) => {
	const srcSetEntries = imageSetEntryNames
		.map((imageSetEntryName) => {
			return getSrcSetEntry(src, imageSetEntryName);
		})
		.filter(
			(srcSetEntry): srcSetEntry is string => typeof srcSetEntry === 'string',
		);

	const srcSet = srcSetEntries.join(',\n');

	return srcSet;
};

export const isBloomreachCDN = (url: string): boolean => {
	const parsedUrl = parseUrl(url) as URL;

	return parsedUrl.origin === environmentConfig.imageCDN;
};
